.title {
  font-family: 'Carter One';
}
.container {
  margin-top: 50px;
  text-align: center;
}

/* Optional style for fetched images */
.images {
  display: flex;
  max-width: 100%;
  margin: 2% auto;
  flex-wrap: wrap;
  align-items: flex-start;
}

.single-photo {
  /* max-width: 500px; */
  width: 90%;
  align-self: auto;
  margin: 10px 5%;
  flex-grow: 1;
  vertical-align: middle;
}

